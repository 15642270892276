import React, { lazy } from 'react'

const ShiftGroupListing = lazy(() =>
  import('./ShiftGroup/ShiftGroupListing').then(module => ({
    default: module.ShiftGroupList,
  }))
)

const ShiftGroupDetails = lazy(() =>
  import('./ShiftGroup/ShiftGroupDetails').then(module => ({
    default: module.ShiftGroupDetails,
  }))
)

const ShiftGroupAssignOption = lazy(() =>
  import('./ShiftGroup/ShiftGroupAssignOption').then(module => ({
    default: module.ShiftGroupAssignOption,
  }))
)

const ShiftGroupShiftPatternAssignment = lazy(() =>
  import('./ShiftGroup/ShiftGroupShiftPatternAssignment').then(module => ({
    default: module.ShiftGroupShiftPatternAssignment,
  }))
)


const ShiftGroupForm = lazy(() =>
  import('./ShiftGroup/ShiftGroupForm').then(module => ({
    default: module.ShiftGroupForm,
  }))
)

const ShiftGroupIndividualAssignForm = lazy(() =>
  import('./ShiftGroup/ShiftGroupIndividualAssignForm').then(module => ({
    default: module.ShiftGroupIndividualAssignForm,
  }))
)

const DutyRosterMenuListing = lazy(() =>
  import('./DutyRoster/DutyRosterMenuListing').then(module => ({
    default: module.DutyRosterMenuListing,
  }))
)

const DutyRosterByIndividual = lazy(() =>
  import('./DutyRoster/DutyRosterByIndividual').then(module => ({
    default: module.DutyRosterByIndividual,
  }))
)

const DutyRosterByGroup = lazy(() =>
  import('./DutyRoster/DutyRosterByGroup').then(module => ({
    default: module.DutyRosterByGroup,
  }))
)

const BatchEntryShiftForm = lazy(() =>
  import('./DutyRoster/BatchEntryShiftForm').then(module => ({
    default: module.BatchEntryShiftForm,
  }))
)

const BatchEntryShiftPattern = lazy(() =>
  import('./DutyRoster/BatchEntryShiftPattern').then(module => ({
    default: module.BatchEntryShiftPattern,
  }))
)

const ExcelUploadMaintenance = lazy(() =>
  import('./DutyRoster/ExcelUploadMaintenance').then(module => ({
    default: module.ExcelUploadMaintenance,
  }))
)

const DutyRosterUploadValidationListing = lazy(() =>
  import('./DutyRoster/UploadValidationListing').then(module => ({
    default: module.DutyRosterUploadValidationListing,
  }))
)

const DutyRosterSummary = lazy(() =>
  import('./DutyRoster/DutyRosterSummary').then(module => ({
    default: module.DutyRosterSummary,
  }))
)

const OvertimePeriodListing = lazy(() =>
  import('./OvertimeApplication/PeriodListing').then(module => ({
    default: module.OvertimePeriodListing,
  }))
)

const OvertimeMenuListing = lazy(() =>
  import('./OvertimeApplication/OvertimeMenuListing').then(module => ({
    default: module.OvertimeMenuListing,
  }))
)

const OvertimeListing = lazy(() =>
  import('./OvertimeApplication/OvertimeListing').then(module => ({
    default: module.OvertimeListing,
  }))
)

const OvertimeForm = lazy(() =>
  import('./OvertimeApplication/OvertimeForm').then(module => ({
    default: module.OvertimeForm,
  }))
)

const OvertimeDetails = lazy(() =>
  import('./OvertimeApplication/OvertimeDetails').then(module => ({
    default: module.OvertimeDetails,
  }))
)

const ExcelUploadMaintenanceOvertime = lazy(() =>
  import('./OvertimeApplication/ExcelUploadMaintenance').then(module => ({
    default: module.ExcelUploadMaintenance,
  }))
)

const UploadValidationListing = lazy(() =>
  import('./OvertimeApplication/UploadValidationListing').then(module => ({
    default: module.UploadValidationListing,
  }))
)


const TimeOffMenuListing = lazy(() =>
  import('./TimeOffApplication/TimeOffMenuListing').then(module => ({
    default: module.TimeOffMenuListing,
  }))
)


// const TimeOffApplicationPeriodListing = lazy(() =>
//   import('./TimeOffApplication/TOApplicationPeriodListing').then(module => ({
//     default: module.TimeOffApplicationPeriodListing,
//   }))
// )

const TimeOffApplicationListing = lazy(() =>
  import('./TimeOffApplication/TOApplicationListing').then(module => ({
    default: module.TimeOffApplicationListing,
  }))
)

const TimeOffApplicationForm = lazy(() =>
  import('./TimeOffApplication/TimeOffApplicationForm').then(module => ({
    default: module.TimeOffApplicationForm,
  }))
)

const PeriodListing = lazy(() =>
  import('./ChangeShiftRequestModule/PeriodListing').then(module => ({
    default: module.PeriodListing,
  }))
)

const RequestListing = lazy(() =>
  import('./ChangeShiftRequestModule/RequestListing').then(module => ({
    default: module.RequestListing,
  }))
)

const RequestForm = lazy(() =>
  import('./ChangeShiftRequestModule/RequestForm').then(module => ({
    default: module.RequestForm,
  }))
)

const AttenRecordsPeriodListing = lazy(() =>
  import('./AttendanceRecords/AttenRecordsPeriodListing').then(module => ({
    default: module.AttenRecordsPeriodListing,
  }))
)

const AttenRecordPeriodOptions = lazy(() =>
  import('./AttendanceRecords/AttendancePeriodOptions').then(module => ({
    default: module.AttenRecordPeriodOptions,
  }))
)

const ManualEntryListing = lazy(() =>
  import('./AttendanceRecords/ManualEntryListing').then(module => ({
    default: module.ManualEntryListing,
  }))
)

const ManualEntryForm = lazy(() =>
  import('./AttendanceRecords/ManualEntryForm').then(module => ({
    default: module.ManualEntryForm,
  }))
)

const AttendanceExcelUploadMaintenance = lazy(() =>
  import('./AttendanceRecords/ExcelUploadMaintenance').then(module => ({
    default: module.AttendanceExcelUploadMaintenance,
  }))
)

const AttendanceUploadValidationListing = lazy(() =>
  import('./AttendanceRecords/UploadValidationListing').then(module => ({
    default: module.AttendanceUploadValidationListing,
  }))
)

const NewAttendanceRecordsListing = lazy(() =>
  import('./NewAttendanceRecords/NewAttendanceRecordsListing').then(module => ({
    default: module.NewAttendanceRecordsListing,
  }))
)

const NewAttendanceRecordsDailyAnalysis = lazy(() =>
  import('./NewAttendanceRecords/DailyAnalysis').then(module => ({
    default: module.NewAttendanceRecordsDailyAnalysis,
  }))
)

const AttendanceAnalysisListing = lazy(() =>
  import('./AttendanceAnalysis/AttendanceAnalysisListing').then(module => ({
    default: module.AttendanceAnalysisListing,
  }))
)

const DailyAnalysis = lazy(() =>
  import('./AttendanceAnalysis/DailyAnalysis').then(module => ({
    default: module.DailyAnalysis,
  }))
)

const AttendanceProcessingListing = lazy(() =>
  import('./AttendanceProcessing/AttendanceProcessingListing').then(module => ({
    default: module.AttendanceProcessingListing,
  }))
)

const AttendanceProcessingSubListing = lazy(() =>
  import('./AttendanceProcessing/AttendanceProcessingSubListing').then(
    module => ({
      default: module.AttendanceProcessingSubListing,
    })
  )
)

const AttendanceProcessingDaily = lazy(() =>
  import('./AttendanceProcessing/AttendanceProcessingDaily').then(module => ({
    default: module.AttendanceProcessingDaily,
  }))
)

const AttendanceProcessingDetail = lazy(() =>
  import('./AttendanceProcessing/AttendanceProcessingDetail').then(module => ({
    default: module.AttendanceProcessingDetail,
  }))
)

const AttendanceProcessingMonthly = lazy(() =>
  import('./AttendanceProcessing/AttendanceProcessingMonthly').then(module => ({
    default: module.AttendanceProcessingMonthly,
  }))
)

const AttendacePostingPeriodListing = lazy(() =>
  import('./AttendancePosting/AttendacePostingPeriodListing').then(module => ({
    default: module.AttendacePostingPeriodListing,
  }))
)

const AttendancePostingListing = lazy(() =>
  import('./AttendancePosting/AttendancePostingListing').then(module => ({
    default: module.AttendancePostingListing,
  }))
)

const EarnLeavePeriodListing = lazy(() =>
  import('./EarnLeavePosting/EarnLeavePeriodListing').then(module => ({
    default: module.EarnLeavePeriodListing,
  }))
)

const EarnLeavePostingListing = lazy(() =>
  import('./EarnLeavePosting/EarnLeavePostingListing').then(module => ({
    default: module.EarnLeavePostingListing,
  }))
)

const AttendanceExclusionList = lazy(() =>
  import('./AttendanceExclusionModule/AttendanceExclusionList').then(
    module => ({
      default: module.AttendanceExclusionList,
    })
  )
)

const ContactTracing = lazy(() =>
  import('./ContactTracing/ContactTracing').then(module => ({
    default: module.ContactTracing,
  }))
)

const ContactedPerson = lazy(() =>
  import('./ContactTracing/ContactedPerson').then(module => ({
    default: module.ContactedPerson,
  }))
)

const TimeAttendanceManagementRoutes = [
  {
    props: { exact: true, path: '/ShiftGroup/ShiftGroupListing' },
    component: <ShiftGroupListing />,
  },
  {
    props: { exact: true, path: '/ShiftGroup/ShiftGroupDetails' },
    component: <ShiftGroupDetails />,
  },
  {
    props: { exact: true, path: '/ShiftGroup/ShiftGroupAssignOption' },
    component: <ShiftGroupAssignOption />,
  },
  {
    props: { exact: true, path: '/ShiftGroup/ShiftGroupShiftPatternAssignment' },
    component: <ShiftGroupShiftPatternAssignment />,
  },
  
  {
    props: { exact: true, path: '/ShiftGroup/ShiftGroupForm/:ShiftGroupID' },
    component: <ShiftGroupForm />,
  },
  {
    props: { exact: true, path: '/ShiftGroup/ShiftGroupIndividualAssignForm' },
    component: <ShiftGroupIndividualAssignForm />,
  },
  {
    props: { exact: true, path: '/DutyRoster/DutyRosterMenuListing' },
    component: <DutyRosterMenuListing />,
  },
  {
    props: { exact: true, path: '/DutyRoster/DutyRosterByIndividual' },
    component: <DutyRosterByIndividual />,
  },
  {
    props: { exact: true, path: '/DutyRoster/DutyRosterByGroup' },
    component: <DutyRosterByGroup />,
  },
  {
    props: { exact: true, path: '/DutyRoster/BatchEntryShiftForm' },
    component: <BatchEntryShiftForm />,
  },
  {
    props: { exact: true, path: '/DutyRoster/BatchEntryShiftPattern' },
    component: <BatchEntryShiftPattern />,
  },
  {
    props: { exact: true, path: '/DutyRoster/ExcelUploadMaintenance' },
    component: <ExcelUploadMaintenance />,
  },
  {
    props: { exact: true, path: '/DutyRoster/ExcelUploadMaintenance' },
    component: <ExcelUploadMaintenance />,
  },
  {
    props: { exact: true, path: '/DutyRoster/UploadValidationListing' },
    component: <DutyRosterUploadValidationListing />,
  },
  {
    props: { exact: true, path: '/DutyRoster/DutyRosterSummary' },
    component: <DutyRosterSummary />,
  },
  {
    props: { exact: true, path: '/OvertimeApplication/PeriodListing' },
    component: <OvertimePeriodListing />,
  },
  {
    props: { exact: true, path: '/OvertimeApplication/OvertimeMenuListing' },
    component: <OvertimeMenuListing />,
  },
  {
    props: { exact: true, path: '/OvertimeApplication/OvertimeListing' },
    component: <OvertimeListing />,
  },
  {
    props: { exact: true, path: '/OvertimeApplication/OvertimeForm' },
    component: <OvertimeForm />,
  },
  {
    props: { exact: true, path: '/OvertimeApplication/OvertimeDetails' },
    component: <OvertimeDetails />,
  },
  {
    props: { exact: true, path: '/OvertimeApplication/ExcelUploadMaintenance' },
    component: <ExcelUploadMaintenanceOvertime />,
  },
  {
    props: {
      exact: true,
      path: '/OvertimeApplication/UploadValidationListing',
    },
    component: <UploadValidationListing />,
  },
  {
    props: { exact: true, path: '/TimeOffApplication/TimeOffMenuListing' },
    component: <TimeOffMenuListing />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/TimeOffApplication/TOApplicationPeriodListing',
  //   },
  //   component: <TimeOffApplicationPeriodListing />,
  // },
  {
    props: { exact: true, path: '/TimeOffApplication/TOApplicationListing' },
    component: <TimeOffApplicationListing />,
  },
  {
    props: {
      exact: true,
      path: '/TimeOffApplication/TimeOffApplicationForm/Add',
    },
    component: <TimeOffApplicationForm mode="Add" />,
  },
  {
    props: { exact: true, path: '/ChangeShiftRequestModule/PeriodListing' },
    component: <PeriodListing />,
  },
  {
    props: { exact: true, path: '/ChangeShiftRequestModule/RequestListing' },
    component: <RequestListing />,
  },
  {
    props: { exact: true, path: '/ChangeShiftRequestModule/RequestForm' },
    component: <RequestForm />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceRecords/AttenRecordsPeriodListing',
    },
    component: <AttenRecordsPeriodListing />,
  },
  {
    props: { exact: true, path: '/AttendanceRecords/AttendancePeriodOptions' },
    component: <AttenRecordPeriodOptions />,
  },
  {
    props: { exact: true, path: '/AttendanceRecords/ManualEntryListing' },
    component: <ManualEntryListing />,
  },
  {
    props: { exact: true, path: '/AttendanceRecords/ManualEntryForm/Add' },
    component: <ManualEntryForm mode="ADD" />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceRecords/ManualEntryForm/Edit/:attendanceRecordID',
    },
    component: <ManualEntryForm mode="EDIT" />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceRecords/AttendanceExcelUploadMaintenance',
    },
    component: <AttendanceExcelUploadMaintenance />,
  },
  {
    props: { exact: true, path: '/AttendanceRecords/UploadValidationListing' },
    component: <AttendanceUploadValidationListing />,
  },
  {
    props: {
      exact: true,
      path: '/NewAttendanceRecords/NewAttendanceRecordsListing',
    },
    component: <NewAttendanceRecordsListing />,
  },
  {
    props: { exact: true, path: '/NewAttendanceRecords/DailyAnalysis' },
    component: <NewAttendanceRecordsDailyAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceAnalysis/AttendanceAnalysisListing',
    },
    component: <AttendanceAnalysisListing />,
  },
  {
    props: { exact: true, path: '/AttendanceAnalysis/DailyAnalysis' },
    component: <DailyAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceProcessing/AttendanceProcessingListing',
    },
    component: <AttendanceProcessingListing />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceProcessing/AttendanceProcessingSubListing',
    },
    component: <AttendanceProcessingSubListing />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceProcessing/AttendanceProcessingDaily',
    },
    component: <AttendanceProcessingDaily />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceProcessing/AttendanceProcessingDetail',
    },
    component: <AttendanceProcessingDetail />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceProcessing/AttendanceProcessingMonthly',
    },
    component: <AttendanceProcessingMonthly />,
  },
  {
    props: {
      exact: true,
      path: '/AttendancePosting/AttendacePostingPeriodListing',
    },
    component: <AttendacePostingPeriodListing />,
  },
  {
    props: { exact: true, path: '/AttendancePosting/AttendancePostingListing' },
    component: <AttendancePostingListing />,
  },
  {
    props: { exact: true, path: '/EarnLeavePosting/EarnLeavePeriodListing' },
    component: <EarnLeavePeriodListing />,
  },
  {
    props: { exact: true, path: '/EarnLeavePosting/EarnLeavePostingListing' },
    component: <EarnLeavePostingListing />,
  },
  {
    props: {
      exact: true,
      path: '/AttendanceExclusionModule/AttendanceExclusionList',
    },
    component: <AttendanceExclusionList />,
  },
  {
    props: { exact: true, path: '/ContactTracing/ContactTracing' },
    component: <ContactTracing />,
  },
  {
    props: { exact: true, path: '/ContactTracing/ContactedPerson' },
    component: <ContactedPerson />,
  },
]

export default TimeAttendanceManagementRoutes
